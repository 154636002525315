'use strict'

import axios from 'axios'

import * as app from './../components/app'
import * as api from './../components/api'

export const init = (ctx, next)=> {
  let url = ''
  if (ctx.querystring !== '') {
    ctx.querystring.split('&').forEach(q => {
      const p = q.split('=')
      if (p[0] == 'url') url = p[1]
    })
  }

  if (url !== '') {
    axios.post(`${app.config.bespokePath}${url}`, {}, {
      headers: {
        'kotive-contact-token': localStorage.getItem('contact-email')+':'+localStorage.getItem('contact-token')
      }
    }).then((resp)=> {
      document.querySelector('#canvas').outerHTML = resp.data
    }).catch((err)=> {
      console.log(err)
      reject(err)
    })
  } else {
    document.querySelector('#canvas').outerHTML = 'Not found'
  }
}
