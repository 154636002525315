'use strict'

import axios from 'axios'
import page from 'page'

import * as api from './../../components/api'
import * as app from './../../components/app'
import * as formHelpers from './../../utils/form-helpers'
import templateSignupFields from './../../templates/auth/signup-fields'

let courseId, planId, plan, querystring, course

export const init = (ctx, next)=> {
  courseId = ctx.params.courseId
  const queryParams = new Object()
  querystring = ctx.querystring
  if (querystring !== '') {
    querystring.split('&').forEach((q) => {
      const param = q.split('=')
      queryParams[param[0]] =  param[1]
    })
    planId = (queryParams.planId !== undefined) ? queryParams.planId : undefined
  }

  render().then( ()=> {
    const container  = document.querySelector('#signupForm')
    container.querySelector('#signupAndPayBtn').classList.add('disabled')

    let contact = localStorage.getItem('contact')
    if (contact != null) {
      contact = JSON.parse(contact)
      container.querySelector('[name="firstname"]').value  = contact.firstname
      container.querySelector('[name="lastname"]').value   = contact.lastname
    }

    const email = localStorage.getItem('contact-email')
    if (email != null) container.querySelector('[name="email"]').value = email

    api.getCourses().then( (courses)=> {
      courses.forEach((c) => { if (c.courseId == courseId) course = c })
      if (course !== undefined) {
        if ((planId === undefined) && (course.plans !== undefined)) planId = course.plans[0].id
        plan = course.plans.find( (p)=> { return p.id === planId})

        if (plan !== undefined) {
          import('/templates/auth/signup-course-details.pug').then((template)=> {
            document.querySelector('#courseDetails').outerHTML = template({
              course: course,
              plan: plan
            })
          })
          if (plan.price == 0) container.querySelector('#signupAndPayBtn').textContent = 'Signup for this course...'
        }

        setupEvents()

        container.querySelector('#signupAndPayBtn').classList.remove('disabled')
      }
    })
  }).catch( (err)=> {
    console.log(err)
  })
}

const render = ()=> {
  return new Promise((resolve, reject)=> {
    import('/templates/auth/signup.pug').then((template)=> {
      document.querySelector('#canvas').outerHTML = template({})
      resolve()
    })
  })
}

export const success = (ctx, next)=> {
  let isLoggedIn = (localStorage.getItem('contact-email') != null) && (localStorage.getItem('contact-token') != null)
  if (isLoggedIn) localStorage.removeItem(`subscription-${ctx.params.courseId}-refreshedAt`)

  import('/templates/auth/signup-success.pug').then((template)=> {
    document.querySelector('#canvas').outerHTML = template({
      config:     app.config,
      isLoggedIn: isLoggedIn
    })
  })
}

const events = (e)=> {
  if (e.target) {
    if (e.target.id === 'signupAndPayBtn') {
      e.preventDefault()
      const container  = document.querySelector('#signupForm')
      const firstname  = container.querySelector('[name="firstname"]').value
      const lastname   = container.querySelector('[name="lastname"]').value
      const email      = container.querySelector('[name="email"]').value

      if (formHelpers.isValidEmail(email)) {
        event.target.classList.add('loading')
        if (plan.price == 0) {
          const payload = [{
            groupId:     app.config.group.groupId,
            firstname:   firstname,
            lastname:    lastname,
            email:       email
          }]
          axios.post(`${app.config.hubPath}learn/subscriptions/signup/gratis/?gid=${app.config.group.groupId}&cid=${courseId}&fid=${course.fieldId}&${querystring}`, payload
          ).then((resp)=> {
          }).catch((err)=> {
            console.log(err)
          }).finally(()=> {
            page.redirect(`/signup/${courseId}/success/`)
          })
        } else {
          const postData = [
            { name: 'merchant_id', value: app.config.payments[0].merchant_id },
            { name: 'merchant_key', value: app.config.payments[0].merchant_key },
            { name: 'return_url', value: `${app.config.baseDomain}/signup/${courseId}/success/` },
            { name: 'cancel_url', value: `${app.config.baseDomain}/signup/${courseId}/` },
            { name: 'notify_url', value: `${app.config.billingPath}billing/webhooks/payfast`},
            { name: 'name_first', value: firstname },
            { name: 'name_last', value: lastname },
            { name: 'email_address', value: email },
            { name: 'm_payment_id', value: `l-0-c-${planId}-${app.config.group.groupId}-${new Date().valueOf().toString()}` },
            { name: 'amount', value: `${plan.price.toFixed(2)}` },
            { name: 'item_name', value: `${course.label.replace('&', '').replace('"', '').replace("'", '').substring(0, 100)}` },
            { name: 'item_description', value: `${course.description.replace('&', '&amp;').replace('"', '&quot;').replace("'", '&apos;').substring(0, 255)}` },
            { name: 'custom_int1', value: `${course.courseId}` },
            { name: 'custom_str1', value: `learn` },
            { name: 'custom_str2', value: `${course.fieldId}` },
            { name: 'custom_str3', value: `${querystring}` },
            { name: 'email_confirmation', value: `1` },
            { name: 'confirmation_address', value: `paid@dcinteractive.co.za` }
            // { name: 'subscription_type', value: `2` }
          ]

          api.getPaymentSignature(app.config.payments[0].provider, postData).then( (signature)=> {
            postData.push({
              name: 'signature',
              value: signature.signature
            })
            // postData.push({
            //   name: 'setup',
            //   value: JSON.stringify({ split_payment : { merchant_id: 10000105, percentage: 10, min: 100, max: 100000}})
            // })
            const form = document.createElement('form')
            form.method = app.config.payments[0].method
            form.action = app.config.payments[0].action

            postData.forEach((p) => {
              const hiddenField = document.createElement('input')
              hiddenField.type  = 'hidden'
              hiddenField.name  = p.name
              hiddenField.value = p.value
              form.appendChild(hiddenField)
            })
            container.appendChild(form)
            form.submit()
          })
        }
      } else {
        console.log('invalid email...')
      }
    }
  }
}

const setupEvents = ()=> {
  document.body.addEventListener('click', events, false)
}

export const onPageExit = (ctx, next)=> {
  document.body.removeEventListener('click', events, false)
  next()
}
