'use strict'

export default function templateCourses(courses) {
  let html = `<div id="coursesList" class="grid-three text-center">`
  if (courses.length == 1) html += `<div></div>`

  courses.forEach((course) => {
    if ((course.visibility == 'public') || (course.isSubscribed === true)) {
      const backgroundColor = (course.properties.backgroundColor !== undefined) ? course.properties.backgroundColor : ''
      html += `<div class="card card-course" data-controller="course-card"><div class="image" style="background: ${backgroundColor};">`

      if ((course.properties.backgroundImage !== undefined) && (course.properties.backgroundImage !== '')) html += `<img src="${course.properties.backgroundImage}">`

      html += `</div><div class="content"><h3>${course.label}</h3><p>${course.description}</p>`

      html += course.authors.map((author)=> {
        let authorHtml = `<p>`
        if (author.photo !== undefined) authorHtml += `<img src="${author.photo}" class="author-photo"/><br/>`
        authorHtml += `<strong>${author.name}</strong><br/><em>${author.role}</em></p>`
        return authorHtml
      })
      if (course.isSubscribed) {
        html += `<p class="continueLink"><a href="/courses/${course.courseId}/">Continue this course &rarr;</a></p>`
      } else if (course.plans) {
        html += `<p class="signuplink text-center"><a class="btn" href="/signup/${course.courseId}/?planId=${course.plans[0].id}">Signup for this course &rarr;</a></p>`
      } else {
        html += `<p class="text-center">Not yet available</p>`
      }

      html += `</div></div>`
    }
  })
  html += `</div>`
  return html
}
