'use strict'

import axios from 'axios'
import page from 'page'
import serialize from 'form-serialize'

import * as app from './../../components/app'
import * as api from './../../components/api'
import * as hubpubsub from './../../utils/hubpubsub'

import templateCourseBreadcrumbs from './../../templates/courses/breadcrumbs'
import templateCourseProgress from './../../templates/courses/progress'
import templateLessonList from './../../templates/lessons/list'

let courseId, lessonId, fieldId, course, lesson

export const init = (ctx, next)=> {
  courseId = lessonId = fieldId = undefined

  render().then( ()=> {
    courseId = ctx.params.courseId
    lessonId = ctx.params.lessonId

    api.getCourses().then( (courses)=> {
      courses.forEach((c) => { if (c.courseId == courseId) course = c })
      document.querySelector('#lesson').innerHTML = `Compiling course information for <em>${course.label}</em>`

      if (course !== undefined) {
        fieldId = course.fieldId

        api.getSubscription(courseId).then( (subscription)=> {
          const properties = JSON.parse(subscription.properties)
          properties.availableLessons.forEach((l, i) => {
            if (l.id == lessonId) {
              lesson = l
              if (l.status == 'inactive') {
                properties.availableLessons[i].status = 'active'
                subscription.properties = JSON.stringify(properties)
                localStorage.setItem(`subscription-${courseId}`, JSON.stringify(subscription))
                localStorage.setItem(`subscription-${courseId}-refreshedAt`, Math.floor(new Date().getTime()/1000))
              }
            }
          })
        
          document.querySelector('#lesson').innerHTML = `Fetching <em>${lesson.label}</em>`
          document.querySelector('#courseBreadcrumbs').innerHTML = templateCourseBreadcrumbs(course.label)
          document.querySelector('#lessonList').innerHTML = templateLessonList(courseId, lessonId, course.label, subscription)
          document.querySelector('#courseProgress').innerHTML = templateCourseProgress(subscription)

          renderLesson(subscription)
        })
      }
    })
  })
}

const renderLesson = (subscription, isHistoric=false)=> {
  let isFirstLesson = false
  let isLastLesson = false

  subscription.properties = JSON.parse(subscription.properties)
  if (subscription.properties.availableLessons[0].id === parseInt(lessonId)) isFirstLesson = true
  if (subscription.properties.availableLessons[subscription.properties.availableLessons.length-1].id === parseInt(lessonId)) isLastLesson = true
  if (subscription.properties.completedLessons !== undefined) {
    if (subscription.properties.completedLessons.indexOf(parseInt(lessonId)) !== -1) isHistoric = true
    if (subscription.properties.availableLessons.length === subscription.properties.completedLessons.length) hubpubsub.publish('course.isComplete', {})
  }

  api.getLesson(courseId, lessonId, fieldId, isHistoric).then( (lessonHtml)=> {
    if (lessonHtml == '') {
      import('/templates/courses/not-subscribed.pug').then((template)=> {
        document.querySelector('#lesson').outerHTML = template({})
      })
    } else if (lessonHtml.indexOf('no-tasks-js') !== -1) {
      import('/templates/courses/lesson-unavailable.pug').then((template)=> {
        document.querySelector('#lesson').outerHTML = template({})
      })
    } else if (lessonHtml.indexOf('action=""') !== -1) {
      import('/templates/courses/lesson-prev-next-lesson.pug').then((btnTemplate)=> {
        document.querySelector('#lesson').outerHTML = `<div class="card" id="lesson">${lessonHtml} ${btnTemplate({})}</div>`
        if (isFirstLesson === true) document.getElementById('prevLessonBtn').parentNode.removeChild(document.getElementById('prevLessonBtn'))
        if (isLastLesson === true) document.getElementById('nextLessonBtn').parentNode.removeChild(document.getElementById('nextLessonBtn'))
        enableUIenhancements()
      })
    } else {
      import('/templates/courses/lesson-save.pug').then((btnTemplate)=> {
        document.querySelector('#lesson').outerHTML = `<div class="card" id="lesson">${lessonHtml} ${btnTemplate({})}</div>`
        enableUIenhancements()
      })
    }
  })
}

const saveProgress = ()=> {
  const elBtn = document.querySelector('#saveProgressBtn')
  elBtn.classList.add('loading')

  const form     = document.querySelector(`#form-${lessonId}`)
  const url      = form.action.replace(`${window.location.origin}/hub`, '')
  const submittedFormData = serialize(form, { empty: false, hash: false })

  api.saveLesson(courseId, lessonId, fieldId, url, submittedFormData).then( (resultHtml)=> {
    if (resultHtml.indexOf('no-tasks-js') !== -1) {
      let possibleNextLessonIds = resultHtml.substring(resultHtml.indexOf('data-ati')+11, resultHtml.indexOf(']', resultHtml.indexOf('data-ati')+11)).split(',')

      if ((submittedFormData == '') && ((possibleNextLessonIds[0] !== undefined) || (possibleNextLessonIds[0] !== ''))) {
        api.getCourseProgress(courseId, fieldId).then( (subscription)=> {
          page.redirect(`/courses/${courseId}/${possibleNextLessonIds[0]}`)
        })
      } else {
        document.querySelector('#lesson').outerHTML = `<div id="lesson" class="loading">Evaluating your answers...</div>`

        api.getSubscription(courseId).then( (subscription)=> {
          document.getElementsByTagName('html')[0].scrollTop = 0
          renderLesson(subscription, true)
          hubpubsub.publish('course.refreshProgress', {})
          document.querySelector(`#lesson-${lessonId}`).classList.add('completed')
        })
      }
    } else {
      elBtn.classList.remove('loading')
      import('/templates/courses/lesson-save.pug').then((btnTemplate)=> {
        document.querySelector('#lesson').innerHTML = resultHtml+btnTemplate({})
      })
    }
  }).catch( (err)=> {
    elBtn.classList.remove('loading')
    console.log(err)
  })
}

const render = ()=> {
  return new Promise((resolve, reject)=> {
    import('/templates/courses/lesson.pug').then((template)=> {
      document.querySelector('#canvas').outerHTML = template({
        lang: app.config.lang,
        supportEmail: app.config.group.value.supportEmail
      })
      resolve()
    })
  })
}

const enableUIenhancements = ()=> {
  const videoElements = document.querySelectorAll('.kotive-task-body .video')
  if (videoElements.length > 0) {
    const width = document.querySelector('.kotive-task-body').clientWidth-30
    const height = width/1.777
    videoElements.forEach( (v)=> {
      v.setAttribute('width', width)
      v.setAttribute('height', height)
    })
  }
}

export const autoChooseLesson = (ctx, next)=> {
  const courseId = ctx.params.courseId
  document.querySelector('#coursesList').outerHTML = `<div id="coursesList" class="loading">Fetching course details...</div>`

  findNextLesson(courseId).then( (chooseLessonId)=> {
    if (chooseLessonId !== undefined) {
      page.redirect(`/courses/${courseId}/${chooseLessonId}`)
    } else {
      api.getLessons(courseId).then( (lessons)=> {
        page.redirect(`/courses/${courseId}/${lessons[lessons.length-1].id}`)
      })
    }
  })
}

const gotoNextLesson = (direction)=> {
  findNextLesson(courseId, lessonId, direction).then( (chooseLessonId)=> {
    document.getElementsByTagName('html')[0].scrollTop = 0

    if (chooseLessonId !== undefined) {
      page.redirect(`/courses/${courseId}/${chooseLessonId}`)
    } else {
      api.getLessons(courseId).then( (lessons)=> {
        page.redirect(`/courses/${courseId}/${lessons[0].id}`)
      })
    }
  })
}

const findNextLesson = (courseId, currentLessonId, direction)=> {
  return new Promise((resolve, reject)=> {
    api.getSubscription(courseId).then( (subscription)=> {
      let chooseLessonId

      subscription.properties = JSON.parse(subscription.properties)
      subscription.properties.availableLessons.forEach((a, i) => {
        if (chooseLessonId === undefined) {
          if (currentLessonId !== undefined) {
            if ((currentLessonId == a.id) && (i < subscription.properties.availableLessons.length-1)) chooseLessonId = subscription.properties.availableLessons[i+direction].id
          } else if (subscription.properties.completedLessons !== undefined) {
            if (subscription.properties.completedLessons.indexOf(a.id) === -1) chooseLessonId = a.id
          } else chooseLessonId = a.id
        }
      })

      resolve(chooseLessonId)
    })
  })
}

export const setupEvents = ()=> {
  document.querySelector('body').addEventListener('click', (e)=> {
    if (e.target) {
      if (e.target.id === 'saveProgressBtn') {
        e.preventDefault()
        saveProgress()
      } else if (e.target.id === 'nextLessonBtn') {
        e.preventDefault()
        gotoNextLesson(1)
      } else if (e.target.id === 'prevLessonBtn') {
        e.preventDefault()
        gotoNextLesson(-1)
      }
    }
  })

  hubpubsub.subscribe('course.refreshProgress', (data)=> {
    api.getCourseProgress(courseId, fieldId).then( (subscription)=> {
      document.querySelector('#courseProgress').innerHTML = templateCourseProgress(subscription)
      hubpubsub.publish('course.activateNextLesson', {})
    })
  })

  hubpubsub.subscribe('course.activateNextLesson', (data)=> {
    findNextLesson(courseId, lessonId, 1).then( (nextLessonId)=> {
      api.getSubscription(courseId).then( (subscription)=> {
        const properties = JSON.parse(subscription.properties)
        properties.availableLessons.forEach((l, i) => {
          if ((l.id == nextLessonId) && (l.status == 'inactive')) properties.availableLessons[i].status = 'active'
        })
        subscription.properties = JSON.stringify(properties)
        localStorage.setItem(`subscription-${courseId}`, JSON.stringify(subscription))
        localStorage.setItem(`subscription-${courseId}-refreshedAt`, Math.floor(new Date().getTime()/1000))
        document.querySelector('#lessonList').innerHTML = templateLessonList(courseId, lessonId, course.label, subscription)
      })
    })
  })

  hubpubsub.subscribe('course.isComplete', (data)=> {})
}

export const onPageExit = (ctx, next)=> {
  next()
}
