'use strict'

import axios from 'axios'
import page from 'page'

import * as configuration from './../config'
import * as api from './../components/api'

export let config

export const startup = ()=> {
  return new Promise((resolve, reject)=> {
    config            = configuration.getConfig()
    config.baseDomain = window.location.origin
    config.subDomain  = extractSubDomain()
    config.appUpdateAvailable = false

    checkForAppUpdates().then( ()=> {
      if (config.appUpdateAvailable) {
        fetchAppUpdates()
        reject('Loading new version of app')
      } else {
        let checkForAppUpdatesId = setInterval(checkForAppUpdates, 300*1000)

        lookupGroup().then((group)=> {
          group.value = JSON.parse(group.value)
          group.value.supportEmail = (group.value.supportEmail !== undefined) ? group.value.supportEmail : 'support@makelearningflow.com'
          config.group = group

          configLanguage().then( ()=> {
            resolve()
          })
        }).catch((err)=> {
          console.log(err)
          reject(err)
        })
      }
    })
  })
}

export const refresh = (ctx, next)=> {
  if (!isLoggedIn()) {
    page.redirect('/login')
  } else if (config.appUpdateAvailable) fetchAppUpdates()
  else next()
}

export const isLoggedIn = ()=> {
  return ((config.group !== undefined) && (config.group.groupId !== undefined) && (localStorage.getItem('contact-email') != null) && (localStorage.getItem('contact-token') != null)) 
}

export const notFound = (ctx, next)=> {
  console.log('notFound')
  next()
}

export const dashboard = ()=> {
  if (!isLoggedIn()) page.redirect('/login')
  else page.redirect('/courses')
}

const extractSubDomain = ()=> {
  const hostname = window.location.hostname.replace(/^www\./, '')
  const parts = hostname.split('.')
  return parts[0]
}

const lookupGroup = ()=> {
  return new Promise((resolve, reject)=> {
    api.getDomain(config.baseDomain).then( (group)=> {
      if (group.id !== undefined) resolve(group)
      else reject('Invalid domain')
    })
  })
}

export const checkForAppUpdates = ()=> {
  return new Promise((resolve, reject)=> {
    axios.head(`/index.html`, {
      headers: {
        'Cache-Control': 'no-cache'
      }
    }).then((resp)=> {
      const storedLastModified  = localStorage.getItem('learn-app-last-modified')
      const lastModified        = resp.headers['last-modified']

      if (storedLastModified == null) {
        localStorage.setItem('learn-app-last-modified', lastModified)
      } else if (localStorage.getItem('learn-app-last-modified') !== lastModified) {
        config.appUpdateAvailable = true
      }
      resolve()
    }).catch((err)=> {
      console.log(err)
      reject(err)
    })
  })
}

export const fetchAppUpdates = ()=> {
  localStorage.removeItem('learn-app-last-modified')
  window.location.reload(true)
}

const configLanguage = ()=> {
  return new Promise((resolve, reject)=> {
    const lang = (config.group.value.lang !== undefined) ? config.group.value.lang : 'en'
    // we have to manually list our lang packs since parcel doesn't work for dynamic includes
    if (lang == 'en') import('/lang/en.json').then((t)=> { config.lang = t })
    else if (lang == 'af') import('/lang/en.json').then((t)=> { config.lang = t })
    resolve()
  })
}
