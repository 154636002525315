'use strict'

export default function templateLessonList(courseId, lessonId, label, subscription) {
  let availableLessons = []
  let completedLessons = []
  let properties = {}

  properties = JSON.parse(subscription.properties)
  availableLessons = properties.availableLessons
  completedLessons = (properties.completedLessons !== undefined) ? properties.completedLessons : []

  let html = `<div class="header">${label}</div><div class="content lessonMenu"><ul>`
  availableLessons.forEach((lesson) => {
    const isCompleted = (completedLessons.indexOf(parseInt(lesson.id)) !== -1) ? true : false
    if ((isCompleted) || (lesson.id == lessonId) || lesson.status == 'active') {
      const completedClass = (isCompleted) ? 'completed' : ''
      if (lesson.id == lessonId) html += `<li id="lesson-${lesson.id}" class="current ${completedClass}"><span>${lesson.label}</span></li>`
      else html += `<li id="lesson-${lesson.id}" class="${completedClass}"><a href="/courses/${courseId}/${lesson.id}">${lesson.label}</a></li>`
    }
  })
  html += `</ul></div>`
  return html
}