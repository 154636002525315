'use strict'

import page from 'page'
import { Application } from 'stimulus'

import * as app from './../../components/app'
import * as api from './../../components/api'

import AuthLoginController from './../../controllers/auth_login_controller'

export const init = (ctx, next)=> {
  const params = {}
  if (ctx.querystring !== '') {
    ctx.querystring.split('&').forEach(q => {
      const p = q.split('=')
      params[p[0]] = p[1]
    })
  }
  render(params).then( ()=> {
    const application = Application.start()
    application.register('auth-login', AuthLoginController)
  }).catch( (err)=> {
    console.log(err)
  })
}

const render = (params)=> {
  return new Promise((resolve, reject)=> {
    import('/templates/auth/login.pug').then((template)=> {
      document.querySelector('#canvas').outerHTML = template({
        params: params
      })
      resolve()
    })
  })
}

export const verify = (ctx, next)=> {
  const params = {}
  ctx.querystring.split('&').forEach(q => {
    const p = q.split('=')
    params[p[0]] = p[1]
  })
  
  if ((params.email != null) && (params.token != null)) {
    if ((localStorage.getItem('contact-email') != null) && (localStorage.getItem('contact-token') != null)) {
      api.renewToken().then( ()=> {
        page.redirect('/courses')
      }).catch( (err)=> {
        localStorage.setItem('contact-email', params.email)
        localStorage.setItem('contact-token', params.token)

        api.renewToken().then( ()=> {
          page.redirect('/courses')
        }).catch( (err)=> {
          page.redirect('/login?expiredToken=true')
        })
      })
    } else {
      localStorage.setItem('contact-email', params.email)
      localStorage.setItem('contact-token', params.token)
      api.renewToken().then( ()=> {
        page.redirect('/courses')
      }).catch( (err)=> {
        page.redirect('/login?expiredToken=true')
      })
    }
  } else {
    page.redirect('/login?expiredToken=true')
  }
}

export const logout = (ctx, next)=> {
  localStorage.clear()
  page.redirect('/login')
}

export const refresh = (ctx, next)=> {
  const contact = {
    id: localStorage.getItem('contact-id'),
    firstname: localStorage.getItem('contact-firstname'),
    lastname: localStorage.getItem('contact-lastname'),
    email: localStorage.getItem('contact-email'),
    token: localStorage.getItem('contact-token'),
  }

  localStorage.clear()

  localStorage.setItem('contact-id', contact.id)
  localStorage.setItem('contact-firstname', contact.firstname)
  localStorage.setItem('contact-lastname', contact.lastname)
  localStorage.setItem('contact-email', contact.email)
  localStorage.setItem('contact-token', contact.token)
  
  page.redirect('/courses')
}