'use strict'

import axios from 'axios'

import * as app from './../../components/app'
import * as api from './../../components/api'
import templateCourses from './../../templates/courses/courses'

export const init = (ctx, next)=> {
  render().then( ()=> {
    if (app.config.group.value.coursesBespokeUI !== undefined) {
      api.getCoursesBespokeUI(app.config.group.value.coursesBespokeUI.url.replace(/\${groupId}/g, app.config.group.groupId)).then( (bespokeHtml)=> {
        document.querySelector('#bespokeUI').outerHTML = bespokeHtml
       })
    }

    api.getCourses().then( (courses)=> {
      document.querySelector('#coursesList').outerHTML = `<div id="coursesList" class="loading">${app.config.lang.courses.verifySubscription}</div>`
      const listOfCalls = courses.map((c) => {
        return api.getSubscription(c.courseId)
      })

      axios.all(listOfCalls).then( (subscriptions)=> {
        courses.forEach((course, i) => {
          course.isSubscribed = (subscriptions.find(s => s.courseId === parseInt(course.courseId)) !== undefined) ? true : false
          courses[i] = course
        })

        courses.sort((a, b)=> {
          return (a.isSubscribed === b.isSubscribed) ? 0 : (a.isSubscribed ? -1 : 1)
        })
        document.querySelector('#coursesList').outerHTML = templateCourses(courses)
      })
    })
  }).catch( (err)=> {
    console.log(err)
  })
}

const render = ()=> {
  return new Promise((resolve, reject)=> {
    import('/templates/courses/courses.pug').then((template)=> {
      document.querySelector('#canvas').outerHTML = template({
        lang: app.config.lang,
        supportEmail: app.config.group.value.supportEmail
      })
      resolve()
    })
  })
}
