'use strict'

import page from 'page'

import * as app from './components/app'
import * as login from './components/auth/login'
import * as signup from './components/auth/signup'
import * as courses from './components/courses/courses'
import * as lesson from './components/courses/lesson'
import * as embed from './components/embed'

const startup = ()=> {

  app.startup().then( ()=> {

    page('/', app.dashboard)

    page('/login', login.init)
    page('/logout', login.logout)
    page('/refresh', login.refresh)
    page('/verify', login.verify)
    page('/signup/:courseId', signup.init)
    page('/signup/:courseId/success', signup.success)

    page('/embed', app.refresh, embed.init)

    page('/courses', app.refresh, courses.init)
    page('/courses/:courseId', app.refresh, lesson.autoChooseLesson)
    page('/courses/:courseId/:lessonId', app.refresh, lesson.init)
    page('*', app.refresh, app.notFound)

    page.start({
      dispatch: true,
      hashbang: false
    })

    page.exit('/signup/:courseId', signup.onPageExit)
    page.exit('/courses/:courseId/:lessonId', lesson.onPageExit)

    lesson.setupEvents()

  }).catch((err)=> {
    document.querySelector('#canvas').classList.remove('loading')
    document.querySelector('#canvas').classList.add('text-center')
    document.querySelector('#canvas').innerHTML = "..."
  })
}

document.addEventListener('DOMContentLoaded', startup)