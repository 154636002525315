'use strict'

export const observeEmailFields = ()=> {
  $('body').on('input', '[type="email"]', (e)=> {
    const email = $(e.target).val()
    if ((email === '') || (email === undefined)) {
      $('.email-indicator').removeClass('checkmark green remove orange')
      $(e.target).parent().parent().find('.invalid-field-message, .error-feedback, .success-feedback').hide()
    } else {
      if (isValidEmail(email)) {
        $('.email-indicator').addClass('checkmark green').removeClass('remove orange')
        $(e.target).parent().parent().find('.invalid-field-message').hide()
      } else {
        $('.email-indicator').removeClass('checkmark green').addClass('remove orange')
        $(e.target).parent().parent().find('.invalid-field-message').show()
      }
    }
  })
}

export const isValidEmail = (email)=> {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
  return re.test(email)
}