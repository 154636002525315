'use strict'

// https://davidwalsh.name/pubsub-javascript
// const subscription = hubpubsub.subscribe('name.change', (data)=> {
//   $("#user-fullname").html(data.name)
// })
// hubpubsub.publish('name.change', {
//   name : "Dr. Who"
// })
// subscription.remove()

let topics = {}
let hOP = topics.hasOwnProperty

export const subscribe = (topic, listener)=> {
  if(!hOP.call(topics, topic)) topics[topic] = []

  let index = topics[topic].push(listener) -1

  return {
    remove: function() {
      delete topics[topic][index]
    }
  }
}

export const publish = (topic, info)=> {
  if(!hOP.call(topics, topic)) return

  topics[topic].forEach(function(item) {
    item(info != undefined ? info : {})
  })
}