'use strict'

import axios from 'axios'
import page from 'page'

import * as app from './app'

export const getDomain = (domain)=> {
  return new Promise((resolve, reject)=> {
    axios.get(`${app.config.hubPath}learn/providers/domain?d=${domain}`).then((resp)=> {
      resolve(resp.data)
    }).catch((err)=> {
      console.log(err)
      reject(err)
    })
  })
}

export const renewToken = ()=> {
  return new Promise((resolve, reject)=> {
    const email = localStorage.getItem('contact-email')
    const token = localStorage.getItem('contact-token')

    if ((app.config.group !== undefined) && (app.config.group.groupId !== undefined) && (email != null) && (token != null)) {
      axios.get(`${app.config.hubPath}contacts/retoken?gid=${app.config.group.groupId}&email=${email}&token=${token}`).then((resp)=> {
        if (resp.data.token === undefined) {
          reject('Invalid token')
        } else {
          localStorage.setItem('contact-id', resp.data.id)
          localStorage.setItem('contact-firstname', resp.data.firstname)
          localStorage.setItem('contact-lastname', resp.data.lastname)
          localStorage.setItem('contact-token', resp.data.token)
          resolve()
        }
      }).catch((err)=> {
        console.log(err)
        page.redirect('/logout')
        // reject(err)
      })
    } else {
      page.redirect('/logout')
      // reject('Missing credentials')
    }
  })
}

export const getSubscription = (courseId, retry=0)=> {
  return new Promise((resolve, reject)=> {
    const maxRetries    = 2
    const refreshBefore = Math.floor(new Date().getTime()/1000) - app.config.refreshRate
    const lastUpdatedAt = localStorage.getItem(`subscription-${courseId}-refreshedAt`)
    const subscription  = localStorage.getItem(`subscription-${courseId}`)

    if ((subscription == null) || (lastUpdatedAt == null) || ((lastUpdatedAt != null) && (parseInt(lastUpdatedAt) < refreshBefore))) {
      axios.get(`${app.config.hubPath}learn/subscriptions/subscription/${app.config.group.groupId}/${courseId}`, {
        headers: {
          'kotive-contact-token': localStorage.getItem('contact-email')+':'+localStorage.getItem('contact-token')
        }
      }).then((resp)=> {
        localStorage.setItem(`subscription-${courseId}`, JSON.stringify(resp.data))
        localStorage.setItem(`subscription-${courseId}-refreshedAt`, Math.floor(new Date().getTime()/1000))
        resolve(resp.data)
      }).catch((err)=> {
        console.log(err)
        if (retry < maxRetries) {
          resolve(getSubscription(courseId, retry++))
        } else {
          console.log(err)
          page.redirect('/logout')
          // reject(err)
        }
      })
    } else {
      resolve(JSON.parse(subscription))
    }
  })
}

export const getCourses = ()=> {
  return new Promise((resolve, reject)=> {
    const refreshBefore = Math.floor(new Date().getTime()/1000) - app.config.refreshRate
    const lastUpdatedAt = localStorage.getItem(`courses-${app.config.group.groupId}-refreshedAt`)

    const courses = localStorage.getItem(`courses-${app.config.group.groupId}`)
    if ((courses != null) && (lastUpdatedAt != null) && (parseInt(lastUpdatedAt) > refreshBefore)) {
      resolve(JSON.parse(courses))
    } else {
      axios.get(`${app.config.hubPath}learn/courses/${app.config.group.groupId}`).then((resp)=> {
        localStorage.setItem(`courses-${app.config.group.groupId}`, JSON.stringify(resp.data))
        localStorage.setItem(`courses-${app.config.group.groupId}-refreshedAt`, Math.floor(new Date().getTime()/1000))
        resolve(resp.data)
      }).catch((err)=> {
        console.log(err)
        page.redirect('/logout')
        // reject(err)
      })
    }
  })
}

export const getCourseProgress = (courseId, fieldId)=> {
  return new Promise((resolve, reject)=> {
    axios.get(`${app.config.hubPath}learn/courses/progress/${app.config.group.groupId}/${courseId}/${fieldId}`, {
      headers: {
        'kotive-contact-token': localStorage.getItem('contact-email')+':'+localStorage.getItem('contact-token')
      }
    }).then((resp)=> {
      localStorage.setItem(`subscription-${courseId}`, JSON.stringify(resp.data))
      localStorage.setItem(`subscription-${courseId}-refreshedAt`, Math.floor(new Date().getTime()/1000))
      resolve(resp.data)
    }).catch((err)=> {
      console.log(err)
      page.redirect('/logout')
      // reject(err)
    })
  })
}

export const getLessons = (courseId)=> {
  return new Promise((resolve, reject)=> {
    let availableLessons = []

    getSubscription(courseId).then((subscription)=> {
      subscription.properties = JSON.parse(subscription.properties)
      availableLessons = subscription.properties.availableLessons
      resolve(availableLessons)
    })
  })
}

export const getLesson = (courseId, lessonId, fieldId, isHistoric)=> {
  return new Promise((resolve, reject)=> {
    let fetchFromCache = false
    let cachedLesson = null
    if (isHistoric) {
      const refreshBefore = Math.floor(new Date().getTime()/1000) - app.config.refreshRate
      const lastUpdatedAt = localStorage.getItem(`lesson-${lessonId}-refreshedAt`)
      cachedLesson = localStorage.getItem(`lesson-${lessonId}`)

      if ((cachedLesson != null) && (lastUpdatedAt != null) && (parseInt(lastUpdatedAt) > refreshBefore)) fetchFromCache = true
    }

    if (fetchFromCache) {
      resolve(cachedLesson)
    } else {
      axios.get(`${app.config.hubPath}learn/lessons/${app.config.group.groupId}/${courseId}/${lessonId}/${fieldId}/${isHistoric}`, {
        headers: {
          'kotive-contact-token': localStorage.getItem('contact-email')+':'+localStorage.getItem('contact-token'),
          'Content-Type':         'text/html'
        }
      }).then((resp)=> {
        if (!isHistoric) {
          if (resp.data.indexOf('no-tasks-js') !== -1) resolve(getLesson(courseId, lessonId, fieldId, true))
          else resolve(resp.data)
        } else {
          localStorage.setItem(`lesson-${lessonId}`, resp.data)
          localStorage.setItem(`lesson-${lessonId}-refreshedAt`, Math.floor(new Date().getTime()/1000))
          resolve(resp.data)
        }
      }).catch((err)=> {
        console.log(err)
        page.redirect('/logout')
        // reject(err)
      })
    }
  })
}

export const saveLesson = (courseId, lessonId, fieldId, url, formData)=> {
  return new Promise((resolve, reject)=> {
    const payload = {
      url: url,
      formData: formData
    }
    axios.post(`${app.config.hubPath}learn/lessons/${app.config.group.groupId}/${courseId}/${lessonId}/${fieldId}`, payload, {
      headers: {
        'kotive-contact-token': localStorage.getItem('contact-email')+':'+localStorage.getItem('contact-token')
      }
    }).then((resp)=> {
      resolve(resp.data)
    }).catch((err)=> {
      console.log(err)
      page.redirect('/logout')
      // reject(err)
    })
  })
}

export const getCoursesBespokeUI = (url)=> {
  return new Promise((resolve, reject)=> {
    const refreshBefore = Math.floor(new Date().getTime()/1000) - app.config.refreshRate
    const lastUpdatedAt = localStorage.getItem(`coursesBespokeUI-${app.config.group.groupId}-refreshedAt`)

    const bespokeHtml = localStorage.getItem(`coursesBespokeUI-${app.config.group.groupId}`)
    if ((bespokeHtml != null) && (lastUpdatedAt != null) && (parseInt(lastUpdatedAt) > refreshBefore)) {
      resolve(bespokeHtml)
    } else {
      axios.post(`${app.config.bespokePath}${url}`, {}, {
        headers: {
          'kotive-contact-token': localStorage.getItem('contact-email')+':'+localStorage.getItem('contact-token')
        }
      }).then((resp)=> {
        localStorage.setItem(`coursesBespokeUI-${app.config.group.groupId}`, resp.data)
        localStorage.setItem(`coursesBespokeUI-${app.config.group.groupId}-refreshedAt`, Math.floor(new Date().getTime()/1000))
        resolve(resp.data)
      }).catch((err)=> {
        console.log(err)
        page.redirect('/logout')
        // reject(err)
      })
    }
  })
}

export const getPaymentSignature = (provider, formData)=> {
  return new Promise((resolve, reject)=> {
    axios.post(`${app.config.billingPath}billing/${provider}/signature`, formData, {
      headers: {
        'kotive-contact-token': localStorage.getItem('contact-email')+':'+localStorage.getItem('contact-token')
      }
    }).then((resp)=> {
      resolve(resp.data)
    }).catch((err)=> {
      console.log(err)
      reject(err)
    })
  })
}